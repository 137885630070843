@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-blue-300
}

.mc {
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden;
    @apply bg-slate-100
}

.smc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 40px 50px;
}

.camera {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: #fff;
}

.smc .camera video {
    width: 100%;
    height: 100%;
}

.smc-one-user .camera {
    width: 75vw;
    height: calc(75vw / (16/9));
}

.smc-two-user .camera {
    width: 40vw;
    height: calc(40vw / (16/9));
}

.smc-three-user .camera {
    width: 34vw;
    height: calc(34vw / (16/9));
}

.smc-four-user .camera {
    width: 34vw;
    height: calc(34vw / (16/9));
}

.smc-five-user .camera {
    width: 28vw;
    height: calc(28vw / (16/9));
}

.smc-six-user .camera {
    width: 28vw;
    height: calc(28vw / (16/9));
}

.smc-seven-user .camera {
    width: 21vw;
    height: calc(21vw / (16/9));
}

.smc-eight-user .camera {
    width: 21vw;
    height: calc(21vw / (16/9));
}

.smc-nine-user .camera {
    width: 21vw;
    height: calc(21vw / (16/9));
}

.mc-ss {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.mc-ss-vertical {
    flex-direction: row;
}

.mc-ss-vertical.mc-ss-reverse {
    flex-direction: row-reverse;
}

.mc-ss-horizontal {
    flex-direction: column;
}

.mc-ss-horizontal.mc-ss-reverse {
    flex-direction: column-reverse;
}

.mc-ss-vertical .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-height: 100vh;
}

.mc-ss-horizontal .sidebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    max-width: 100vw;
}

.mc-ss .screen-sharing {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    @apply bg-gray-700
}

.mc-ss-vertical .screen-sharing {
    width: 75vw;
    height: calc(75vw / (16/9));
}

.mc-ss-horizontal .screen-sharing {
    width: 75vw;
    height: calc(75vw / (16/9));
}

.mc-ss-vertical .sidebar .camera {
    width: 15vw;
    height: calc(15vw / (16/9));
}

.mc-ss-horizontal .sidebar .camera {
    width: 13vw;
    height: calc(13vw / (16/9));
}

.mc-bg {
    background-image: unset;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    position: absolute;
    z-index: 30;
    width: 200px;
    max-width: 20%;
    height: auto;
}

.logo-top-right {
    right: 0;
    top: 0;
}

.logo-top-left {
    left: 0;
    top: 0;
}

.logo-bottom-right {
    right: 0;
    bottom: 0;
}

.logo-bottom-left {
    left: 0;
    bottom: 0;
}

.name-badge-container {
    position: absolute;
    z-index: 10;
    left: 6px;
    bottom: 6px;
}

.name-badge-container .name-badge {
    background-color: #111826;
    color: #fff;
    @apply inline-flex items-center px-2 py-1 rounded-md text-sm font-medium
}

.name-badge-container .name-badge svg {
    @apply mr-1.5
}

.no-camera-user {
    @apply w-full h-full flex items-center justify-center bg-gray-700
}

.no-camera-user svg {
    @apply text-gray-200 h-1/3 w-1/3
}

.sidebar .no-camera-user svg {
    @apply h-1/2 w-1/2
}

.sidebar .name-badge-container {
    left: 6px;
    bottom: 6px;
}

.sidebar .name-badge-container .name-badge {
    @apply px-2 py-1 rounded-md text-xs
}

.sidebar .name-badge-container .name-badge svg {
    @apply mr-1
}

.overlay {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.overlay img {
    width: 100%;
    height: 100%;
}
